@font-face {
  font-family: UniversalSans;
  src: url('~static/fonts/Manifold-Sans-Display-320.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: UniversalSans;
  src: url('~static/fonts/Manifold-Sans-Display-320Italic.woff2')
    format('woff2');
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
  font-family: UniversalSans;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --tp-base-background-color: hsla(230, 5%, 90%, 1);
  --tp-base-shadow-color: hsla(0, 0%, 0%, 0.1);
  --tp-button-background-color: hsla(230, 7%, 75%, 1);
  --tp-button-background-color-active: hsla(230, 7%, 60%, 1);
  --tp-button-background-color-focus: hsla(230, 7%, 65%, 1);
  --tp-button-background-color-hover: hsla(230, 7%, 70%, 1);
  --tp-button-foreground-color: hsla(230, 10%, 30%, 1);
  --tp-container-background-color: hsla(230, 15%, 30%, 0.2);
  --tp-container-background-color-active: hsla(230, 15%, 30%, 0.32);
  --tp-container-background-color-focus: hsla(230, 15%, 30%, 0.28);
  --tp-container-background-color-hover: hsla(230, 15%, 30%, 0.24);
  --tp-container-foreground-color: hsla(230, 10%, 30%, 1);
  --tp-groove-foreground-color: hsla(230, 15%, 30%, 0.1);
  --tp-input-background-color: hsla(230, 15%, 30%, 0.1);
  --tp-input-background-color-active: hsla(230, 15%, 30%, 0.22);
  --tp-input-background-color-focus: hsla(230, 15%, 30%, 0.18);
  --tp-input-background-color-hover: hsla(230, 15%, 30%, 0.14);
  --tp-input-foreground-color: hsla(230, 10%, 30%, 1);
  --tp-label-foreground-color: hsla(230, 10%, 30%, 0.7);
  --tp-monitor-background-color: hsla(230, 15%, 30%, 0.1);
  --tp-monitor-foreground-color: hsla(230, 10%, 30%, 0.5);
  --tp-font-family: Helvetica;
}

canvas {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
